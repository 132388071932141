aside,
div.search,
header .tooltip,
nav.primary,
footer .network,
.share,
.sequence
{
  display: none;
}
.content {
  padding: 0;
}
main {
  float: none;
  width: auto;
  padding: 0;
}
body {
  background: none;
}